import { FC } from 'react';
import { Outlet } from 'react-router-dom';

const Main: FC = () => {
  return (
    <div className="min-h-[calc(100vh_-_259px)] h-auto sm:h-[calc(100vh_-_100px)]">
      <Outlet />
    </div>
  );
};

export default Main;
