import tw from 'tailwind-styled-components';
import BorderContainer from '@/components/common/BorderContainer';

const TokenWrapper = tw(BorderContainer)`
  h-[44px]
  flex
  gap-2
  items-center
  justify-between
  pl-2
  pr-3
  bg-black_02
  rounded-[8px]
`;

export default TokenWrapper;
