import { useEffect, useState } from 'react';

export const useMobile = () => {
  const [isMobile, setMobile] = useState<boolean | null>(null);

  useEffect(() => {
    const isMobile = /Mobi|Android|iPhone/i.test(navigator.userAgent);
    setMobile(isMobile);
  }, []);

  return isMobile;
};
