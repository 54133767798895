import numeral from 'numeral'
import { formatUnits, parseUnits } from '@ethersproject/units';
import { BigNumber } from '@ethersproject/bignumber';

export function format(bn: BigNumber, decimals: number, temp = '0,0.[000000]') {
  return numeral(formatUnits(bn, decimals)).format(temp).toLocaleUpperCase();
}

export function toBigNumber(str: string, decimals: number) {
  return parseUnits(str, 18).div(parseUnits('1', 18 - decimals));
}