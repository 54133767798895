import { FC, PropsWithChildren } from "react";
import { Dialog as HeadlessDialog } from "@headlessui/react";
import closeIcon from "@/assets/close.svg";

interface DialogProps {
  isOpen: boolean;
  title: string;
  onClose: () => void;
}

const { Panel, Title } = HeadlessDialog;

const Dialog: FC<PropsWithChildren<DialogProps>> = ({
  isOpen,
  title,
  onClose,
  children,
}) => {
  return (
    <HeadlessDialog open={isOpen} onClose={onClose} className="relative z-10">
      <div
        className="fixed inset-0 bg-black/50 backdrop-blur-[8px]"
        aria-hidden="true"
      />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Panel className={`w-[480px] px-10 py-6 rounded-[12px] bg-black_03`}>
          <Title className="flex items-center justify-between h-10 pr-2">
            <span className="text-white text-[24px] leading-6 font-bold">
              {title}
            </span>
            <img
              className="w-6 h-6 text-white cursor-pointer"
              src={closeIcon}
              alt="close"
              onClick={onClose}
            />
          </Title>
          {children}
        </Panel>
      </div>
    </HeadlessDialog>
  );
};

export default Dialog;
