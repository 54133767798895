import { FC, useMemo, useState, useCallback } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate, useParams } from 'react-router-dom';
import { parseEther, formatEther } from '@ethersproject/units';
import { MaxUint256 } from '@ethersproject/constants';
import TokenWrapper from '@/components/common/TokenWrapper';
import InputNumber from '@/components/common/InputNumber';
import Button from '@/components/common/Button';
import TimeLine from '@/components/common/TimeLine';
import TimeCountDown from '@/components/common/TimeCountDown';
import { ReactComponent as ArrowLeftIcon } from '@/assets/arrowLeft.svg';
import ethIcon from '@/assets/eth.svg';
import { hooks } from '@/connectors/metamask';
import {
  useDetail,
  useStage,
  useClaimETH,
  useClaimToken,
  useCommit,
} from '@/hooks/presale';
import { useETHBalance } from '@/hooks/balance';
import * as currency from '@/helpers/currency';
import Loading from '@/components/common/Loading';
import CheckWallet from '@/components/CheckWallet';
import Whitelist from '@/components/Whitelist';
import PRESALES from '@/constants/presales';

dayjs.extend(utc);

const { useProvider } = hooks;

const ClaimETH: FC<{ disabled: boolean }> = ({ disabled }) => {
  const { address } = useParams();
  const [loading, setLoading] = useState(false);
  const provider = useProvider();
  const claimETH = useClaimETH(address as string);
  const handleClick = useCallback(async () => {
    try {
      if (!provider) throw new Error('provider is not exist');
      setLoading(true);
      const txData = claimETH();
      const signer = provider.getSigner();
      const tx = await signer.sendTransaction(txData);

      await tx.wait();
      setLoading(false);
    } catch (err: any) {
      console.log(err.message);
      setLoading(false);
      window.alert('Transaction failed!');
    }
  }, [provider, claimETH]);

  return (
    <Button disabled={disabled} loading={loading} onClick={handleClick}>
      Claim ETH
    </Button>
  );
};

const ClaimToken: FC<{ disabled: boolean }> = ({ disabled }) => {
  const { address } = useParams();
  const [loading, setLoading] = useState(false);
  const provider = useProvider();
  const claimToken = useClaimToken(address as string);
  const handleClick = useCallback(async () => {
    try {
      if (!provider) throw new Error('provider is not exist');
      setLoading(true);
      const { to, data, value } = claimToken();
      const signer = provider.getSigner();
      const tx = await signer.sendTransaction({
        to,
        value,
        data,
      });

      await tx.wait();
      setLoading(false);
    } catch (err: any) {
      console.log(err.message);
      setLoading(false);
      window.alert('Transaction failed!');
    }
  }, [provider, claimToken]);

  return (
    <Button disabled={disabled} loading={loading} onClick={handleClick}>
      Claim CHAT
    </Button>
  );
};

const Buy: FC<{ value: string }> = ({ value }) => {
  const { address } = useParams();
  const [loading, setLoading] = useState(false);
  const provider = useProvider();
  const commit = useCommit(address as string);
  const handleClick = useCallback(async () => {
    try {
      if (!provider) throw new Error('provider is not exist');
      setLoading(true);
      const txData = commit(value);
      const signer = provider.getSigner();
      const tx = await signer.sendTransaction(txData);

      await tx.wait();
      setLoading(false);
    } catch (err: any) {
      console.log(err.message);
      setLoading(false);
      window.alert('Transaction failed!');
    }
  }, [provider, value, commit]);

  return (
    <Button loading={loading} onClick={handleClick}>
      Buy and Farm
    </Button>
  );
};

const Accelerate: FC = () => {
  const navigate = useNavigate();
  const { address } = useParams();
  const { detail, apr } = useDetail(address as string);
  const [amount, setAmount] = useState('');
  const { started, ended, claim, next } = useStage(
    detail?.startTime,
    detail?.endTime,
    detail?.receiveTime,
  );
  const balance = useETHBalance();
  const current = useMemo(() => {
    if (claim.active) return 3;
    if (ended.active) return 2;
    if (started.active) return 1;
    return 0;
  }, [claim.active, ended.active, started.active]);
  const handleMax = useCallback(() => {
    if (detail && balance) {
      setAmount(
        formatEther(detail.maxCommit.gte(balance) ? balance : detail.maxCommit),
      );
    }
  }, [balance, detail, setAmount]);
  const amountBn = useMemo(() => {
    try {
      return parseEther(amount);
    } catch {
      return MaxUint256;
    }
  }, [amount]);

  if (!detail) return <Loading />;
  return (
    <div className="flex flex-col h-full">
      <div className="px-4 pt-2 sm:px-8 sm:pt-4">
        <div
          className="inline-flex items-center cursor-pointer shrink-0 flex-0"
          onClick={() => navigate('/launchpad')}
        >
          <ArrowLeftIcon className="w-5 h-5" />
          <div className="ml-2 text-white text-[16px] leading-[21px] font-bold">
            Back
          </div>
        </div>
      </div>
      <div className="flex-1 px-8 py-6 overflow-auto sm:py-12">
        <div className="mx-auto sm:w-[666px] flex flex-col">
          <div className="text-white text-[36px] leading-[41px] sm:text-[48px] sm:leading-[55px] font-[Android101] text-center">
            Accelerate chatfi.ai
          </div>
          <div className="mt-4">
            <TimeLine
              steps={[
                'Preparation',
                `${PRESALES[address as string] ? 'Private' : 'Public'} Round`,
                'Close',
                'Claims',
              ]}
              current={current}
            />
            <div className="flex flex-col gap-6 mt-9 sm:flex-row">
              <div className="w-full sm:w-1/2 p-6 bg-black/50 rounded-[16px] order-last sm:order-first">
                <div>
                  <div className="pb-2 border-b border-solid border-blue">
                    <div className="text-white text-[20px] leading-[26px] font-bold">
                      SALE INFO
                    </div>
                  </div>
                  <div className="flex flex-col items-start gap-3 mt-3 mb-6">
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Starting time
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {dayjs
                          .unix(detail.startTime)
                          .utc()
                          .format('ha [UTC], D[th] MMMM, YYYY')}
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Closing time
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {dayjs
                          .unix(detail.endTime)
                          .utc()
                          .format('ha [UTC], D[th] MMMM, YYYY')}
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Hard cap
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {currency.format(detail.ethersToRaise, 18)} ETH
                      </div>
                    </div>
                    {detail.refundThreshold.gt(0) && (
                      <div>
                        <div className="text-gray text-[12px] leading-4">
                          Soft cap
                        </div>
                        <div className="text-white text-[14px] leading-[18px]">
                          {currency.format(detail.refundThreshold, 18)} ETH
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Price
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {currency.format(detail.price, 18)} ETH per token
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        CHAT amount in sale
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {currency.format(detail.tokensToSell, 18)} CHAT
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Individual cap
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {detail.maxCommit.gte(detail.ethersToRaise) ? (
                          <>
                            More than {currency.format(detail.minCommit, 18)}{' '}
                            ETH
                          </>
                        ) : (
                          <>
                            {currency.format(detail.minCommit, 18)} ETH -{' '}
                            {currency.format(detail.maxCommit, 18)} ETH
                          </>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Overflow farming bonus
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {currency.format(detail.totalEmission, 18)} CHAT
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Total Raised
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {started.active
                          ? `${currency.format(
                              detail.totalCommitments,
                              18,
                            )} ETH`
                          : '-'}
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="pb-2 border-b border-solid border-blue">
                    <div className="text-white text-[20px] leading-[26px] font-bold">
                      {!started.active
                        ? 'SALE OPENS IN'
                        : !ended.active
                        ? 'SALE CLOSE IN'
                        : 'SALE CLAIM IN'}
                    </div>
                  </div>
                  <TimeCountDown ts={next} />
                </div>
              </div>
              <div className="w-full sm:w-1/2">
                <div className="p-6 bg-black/50 rounded-[16px]">
                  <div className="pb-2 border-b border-solid border-blue">
                    <div className="text-white text-[20px] leading-[26px] font-bold">
                      SALE
                    </div>
                  </div>
                  <div className="flex flex-col gap-1 mt-4">
                    <div className="text-gray text-[14px] leading-[18px]">
                      Amount
                    </div>
                    <TokenWrapper>
                      <div className="flex items-center justify-between flex-grow gap-3">
                        <div className="flex items-center gap-2 shrink-0">
                          <img className="w-6 h-6" src={ethIcon} alt="eth" />
                          <div className="text-white text-[14px] leading-[14px] font-semibold">
                            ETH
                          </div>
                        </div>
                        <div>
                          <InputNumber
                            value={amount}
                            disabled={!started.active || ended.active}
                            onChange={(value) => setAmount(value)}
                          />
                        </div>
                      </div>
                      <button
                        className="w-[42px] h-[20px] shrink-0 flex items-center justify-center text-white text-[12px] rounded-[3px] bg-blue"
                        disabled={!started.active || ended.active}
                        onClick={handleMax}
                      >
                        MAX
                      </button>
                    </TokenWrapper>
                    <div className="text-white/50 text-[12px] leading-4 text-right">
                      Balance:{' '}
                      {balance ? `${currency.format(balance, 18)} ETH` : '-'}
                    </div>
                  </div>
                  <div className="mt-4">
                    {ended.active ? (
                      <Button disabled>Sale Closed</Button>
                    ) : (
                      <CheckWallet>
                        <Whitelist
                          presale={address as string}
                          started={started.active}
                        >
                          {amount && balance && amountBn.gt(balance) ? (
                            <Button disabled>Insufficient balance</Button>
                          ) : amount &&
                            amountBn
                              .add(detail.commitment)
                              .lt(detail.minCommit) ? (
                            <Button disabled>Incorrect range</Button>
                          ) : amount &&
                            amountBn
                              .add(detail.commitment)
                              .gt(detail.maxCommit) ? (
                            <>
                              {detail.commitment.gt(0) ? (
                                <Button disabled>
                                  Exceed your max allocation
                                </Button>
                              ) : (
                                <Button disabled>Incorrect range</Button>
                              )}
                            </>
                          ) : (
                            <Buy value={amount} />
                          )}
                        </Whitelist>
                      </CheckWallet>
                    )}
                  </div>
                </div>
                <div className="mt-6 p-6 bg-black/50 rounded-[16px]">
                  <div className="pb-2 border-b border-solid border-blue">
                    <div className="text-white text-[20px] leading-[26px] font-bold">
                      CLAIM
                    </div>
                  </div>
                  <div className="flex flex-col items-start gap-3 mt-3 mb-6">
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Total purchased
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {currency.format(detail.commitment, 18)} ETH
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Claimable
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {currency.format(detail.tokensToReceive, 18)} CHAT
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">APR</div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {apr.gt(0)
                          ? `${currency.format(apr, 18, '0.00')} %`
                          : '-'}
                      </div>
                    </div>
                    <div>
                      <div className="text-gray text-[12px] leading-4">
                        Overflow farming bonus
                      </div>
                      <div className="text-white text-[14px] leading-[18px]">
                        {currency.format(detail.emission, 18)} CHAT
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-4 mt-4">
                    <ClaimETH
                      disabled={
                        !ended.active ||
                        detail.finalToken.gt(0) ||
                        detail.commitment.lte(0)
                      }
                    />
                    <ClaimToken
                      disabled={
                        !claim.active ||
                        !detail.finished ||
                        !detail.finalToken.gt(0)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4 sm:mt-6 p-6 bg-black/50 rounded-[16px]">
              <div className="text-white text-[12px] leading-4">
                Due to the unstable network of zkSync Era, you might experience
                delays or fail to process while making a large purchase. Please
                be patient, refresh, and try again later.
              </div>
            </div>
            <div className="mt-4 sm:mt-6 p-6 bg-black/50 rounded-[16px]">
              <div className="pb-2 border-b border-solid border-blue">
                <div className="text-white text-[20px] leading-[26px] font-bold">
                  DISCLAIMER
                </div>
              </div>
              <div className="mt-4 text-white text-[12px] leading-4">
                Please note that neither this webpage nor any ChatFi media
                content, including other published materials on this website or
                media, should be considered as investment advice, financial
                advice, trading advice, or any kind of advice.Therefore, you
                should not interpret any content presented on this website as
                such. All users who visit this website are responsible for
                comprehending the relevant risks involved and assuming sole
                liability for assessing the advantages and disadvantages related
                to utilizing any information or other content displayed on this
                website before making any decisions based on such information.
                You understand that investing in the cryptocurrency market comes
                with high volatility, and there is a real possibility of losing
                all the funds allocated in the cryptocurrency market. Thus,
                please refrain from using funds that are unaffordable to lose
                when acquiring cryptocurrencies or other digital tokens.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accelerate;
