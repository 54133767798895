import { FC } from 'react';

interface TimeLineProps {
  steps: string[];
  current: number;
}

const TimeLine: FC<TimeLineProps> = ({ steps, current }) => {
  return (
    <div className="flex justify-between relative sm:px-[10px]">
      <div className="absolute top-[27px] left-[34px] right-[20px] sm:top-[38px] sm:left-[54px] sm:right-[36px] h-[1px] sm:h-[2px] bg-blue"></div>
      {steps.map((step, index) => (
        <div
          key={index}
          className="flex flex-col gap-2 sm:gap-[10px] items-center"
        >
          <div className="text-white text-[12px] leading-4 sm:text-[16px] sm:leading-[21px] font-bold">
            {step}
          </div>
          <div
            className={`w-2 h-2 sm:w-4 sm:h-4 relative rounded-full ${
              current === index ? 'bg-red' : 'bg-blue'
            } z-[1]`}
          />
        </div>
      ))}
    </div>
  );
};

export default TimeLine;
