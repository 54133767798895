import { useState, useMemo, useEffect, useCallback } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { Interface } from '@ethersproject/abi'
import { parseEther } from '@ethersproject/units'
import { hooks as networkHooks } from '@/connectors/network'
import { hooks as metmasHooks } from '@/connectors/metamask'
import { useMulticall } from './multicall'
import { useBlockNumber } from './blockNumber'
import { useNow } from './time'
import Presale_ABI from '@/abis/Presale.json'
import { Presale } from '@/typechain'
import { PresaleInterface } from '@/typechain/Presale'

interface MetaData {
  address: string
  salesToken: string
  emissionToken: string
  tokensToSell: BigNumber
  ethersToRaise: BigNumber
  refundThreshold: BigNumber
  totalEmission: BigNumber
  startTime: number
  endTime: number
  receiveTime: number
  minCommit: BigNumber
  maxCommit: BigNumber
  price: BigNumber
  totalCommitments: BigNumber
}

interface SaleData {
  totalCommitments: BigNumber
  commitment: BigNumber
  finalToken: BigNumber
  finalEmission: BigNumber
  missedEmission: BigNumber
  ethersToRefund: BigNumber
  tokensToReceive: BigNumber
  emission: BigNumber
  finished: boolean
}

const { useProvider } = networkHooks
const { useAccount } = metmasHooks
const ZERO = BigNumber.from(0)

export const useContract = (presale: string) => {
  const provider = useProvider()

  return useMemo(
    () =>
      new Contract(presale, Presale_ABI, provider) as unknown as Presale,
    [presale, provider]
  )
}

export const useFetchMetaData = () => {
  const multicall = useMulticall()

  return useCallback(async (presale: string): Promise<MetaData> => {
    const iface = new Interface(Presale_ABI)
    const { returnData } = await multicall.callStatic.aggregate([
      { target: presale, callData: iface.encodeFunctionData('salesToken') },
      { target: presale, callData: iface.encodeFunctionData('emissionToken') },
      { target: presale, callData: iface.encodeFunctionData('tokensToSell') },
      { target: presale, callData: iface.encodeFunctionData('ethersToRaise') },
      { target: presale, callData: iface.encodeFunctionData('refundThreshold') },
      { target: presale, callData: iface.encodeFunctionData('totalEmission') },
      { target: presale, callData: iface.encodeFunctionData('startTime') },
      { target: presale, callData: iface.encodeFunctionData('endTime') },
      { target: presale, callData: iface.encodeFunctionData('receiveTime') },
      { target: presale, callData: iface.encodeFunctionData('minCommit') },
      { target: presale, callData: iface.encodeFunctionData('maxCommit') },
      { target: presale, callData: iface.encodeFunctionData('totalCommitments') }
    ])
    const [
      [salesToken],
      [emissionToken],
      [tokensToSell],
      [ethersToRaise],
      [refundThreshold],
      [totalEmission],
      [startTime],
      [endTime],
      [receiveTime],
      [minCommit],
      [maxCommit],
      [totalCommitments]
    ] = [
      iface.decodeFunctionResult('salesToken', returnData[0]) as [string],
      iface.decodeFunctionResult('emissionToken', returnData[1]) as [string],
      iface.decodeFunctionResult('tokensToSell', returnData[2]) as [BigNumber],
      iface.decodeFunctionResult('ethersToRaise', returnData[3]) as [BigNumber],
      iface.decodeFunctionResult('refundThreshold', returnData[4]) as [BigNumber],
      iface.decodeFunctionResult('totalEmission', returnData[5]) as [BigNumber],
      iface.decodeFunctionResult('startTime', returnData[6]) as [BigNumber],
      iface.decodeFunctionResult('endTime', returnData[7]) as [BigNumber],
      iface.decodeFunctionResult('receiveTime', returnData[8]) as [BigNumber],
      iface.decodeFunctionResult('minCommit', returnData[9]) as [BigNumber],
      iface.decodeFunctionResult('maxCommit', returnData[10]) as [BigNumber],
      iface.decodeFunctionResult('totalCommitments', returnData[11]) as [BigNumber]
    ]

    return {
      address: presale,
      salesToken,
      emissionToken,
      tokensToSell,
      ethersToRaise,
      refundThreshold,
      totalEmission,
      price: ethersToRaise.mul(parseEther('1')).div(tokensToSell),
      startTime: startTime.toNumber(),
      endTime: endTime.toNumber(),
      receiveTime: receiveTime.toNumber(),
      minCommit,
      maxCommit,
      totalCommitments
    }
  }, [multicall])
}

export const useFetchSaleData= () => {
  const multicall = useMulticall()
  const account = useAccount()

  return useCallback(async (presale: string): Promise<SaleData> => {
    const iface = new Interface(Presale_ABI)
    if (!account) {
      const { returnData } = await multicall.callStatic.aggregate([
        { target: presale, callData: iface.encodeFunctionData('totalCommitments') },
        { target: presale, callData: iface.encodeFunctionData('finished') },
      ])
      const [[totalCommitments], [finished]] = [
        iface.decodeFunctionResult('totalCommitments', returnData[0]) as [BigNumber],
        iface.decodeFunctionResult('finished', returnData[1]) as [boolean]
      ]

      return {
        totalCommitments,
        commitment: ZERO,
        finalToken: ZERO,
        finalEmission: ZERO,
        missedEmission: ZERO,
        ethersToRefund: ZERO, 
        tokensToReceive: ZERO, 
        emission: ZERO,
        finished
      }
    } else {
      const contract = new Contract(presale, Presale_ABI, multicall.provider) as Presale
      const { returnData } = await multicall.callStatic.aggregate([
        { target: presale, callData: contract.interface.encodeFunctionData('totalCommitments') },
        { target: presale, callData: contract.interface.encodeFunctionData('finished') },
        { target: presale, callData: contract.interface.encodeFunctionData('commitments', [account]) },
        { target: presale, callData: contract.interface.encodeFunctionData('finalTokens', [account]) },
        { target: presale, callData: contract.interface.encodeFunctionData('finalEmissions', [account]) },
        { target: presale, callData: contract.interface.encodeFunctionData('missedEmissions', [account]) },
      ])
      const [
        [totalCommitments],
        [finished],
        [commitment],
        [finalToken],
        [finalEmission],
        [missedEmission],
      ] = [
        iface.decodeFunctionResult('totalCommitments', returnData[0]) as [BigNumber],
        iface.decodeFunctionResult('finished', returnData[1]) as [boolean],
        iface.decodeFunctionResult('commitments', returnData[2]) as [BigNumber],
        iface.decodeFunctionResult('finalTokens', returnData[3]) as [BigNumber],
        iface.decodeFunctionResult('finalEmissions', returnData[4]) as [BigNumber],
        iface.decodeFunctionResult('missedEmissions', returnData[5]) as [BigNumber],
      ]
      let ethersToRefund = ZERO
      let tokensToReceive = ZERO
      let emission = ZERO

      try {
        [ethersToRefund, tokensToReceive, emission] = await contract.callStatic.simulateClaim({ from: account })
      } catch (err) {
        console.log('simulateClaim error', err)
      }

      return {
        totalCommitments,
        commitment,
        finalToken,
        finalEmission,
        missedEmission,
        ethersToRefund, 
        tokensToReceive, 
        emission,
        finished
      }
    }
  }, [account, multicall])
}

export const useList = (presales: string[]) => {
  const [list, setList] = useState<MetaData[] | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const fetchMetaData = useFetchMetaData()

  useEffect(
    () => {
      setError(null)
      Promise.all(presales.map(presale => fetchMetaData(presale)))
        .then(res => setList(res))
        .catch(err => {
          console.log(err)
          setError(err)
        })
    },
    [presales, fetchMetaData, setList]
  )

  return { list, error }
}

export const useDetail = (presale: string) => {
  const [detail, setDetail] = useState<(MetaData & SaleData) | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const fetchMetaData = useFetchMetaData()
  const fetchSaleData = useFetchSaleData()
  const blockNumber = useBlockNumber()
  const apr = useMemo(() => {
    if (!detail) return ZERO
    const { commitment, emission, price } = detail
    if (commitment.lte(0)) return ZERO
    if (emission.lte(0)) return ZERO
    return emission.mul(price).div(commitment).mul(365).mul(100)
  }, [detail])
  const fetch = useCallback(async () => {
    try {
      const saledata = await fetchSaleData(presale)

      setDetail(detail => detail && ({ ...detail, ...saledata }))
    } catch (err) {
      console.log('fetch sale data error', presale, err)
    }
  }, [presale, fetchSaleData, setDetail])

  useEffect(
    () => {
      setError(null)
      Promise.all([
        fetchMetaData(presale),
        fetchSaleData(presale)
      ]).then(([metadata, saledata]) => {
        setDetail({ ...metadata, ...saledata })
      }).catch(err => setError(err))
    },
    [presale, fetchMetaData, fetchSaleData, setDetail]
  )

  useEffect(
    () => {
      fetch()
    },
    [blockNumber, fetch]
  )

  return { 
    detail,
    apr,
    error, 
    fetch 
  }
}

export const useCommit = (presale: string) => {
  return useCallback((value: string) => {
    const iface = new Interface(Presale_ABI) as unknown as PresaleInterface;
    const data = iface.encodeFunctionData('commit');

    return {
      to: presale,
      data,
      value: parseEther(value).toHexString(),
    }
  }, [presale])
}

export const useClaimETH = (presale: string) => {
  return useCallback(() => {
    const iface = new Interface(Presale_ABI) as unknown as PresaleInterface;
    const data = iface.encodeFunctionData('claim');

    return {
      to: presale,
      data,
      value: BigNumber.from(0).toHexString(),
    }
  }, [presale])
}

export const useClaimToken = (presale: string) => {
  return useCallback(() => {
    const iface = new Interface(Presale_ABI) as unknown as PresaleInterface;
    const data = iface.encodeFunctionData('claim2');

    return {
      to: presale,
      data,
      value: BigNumber.from(0).toHexString(),
    }
  }, [presale])
}

export const useStage = (
  startTime = 0,
  endTime = 0,
  receiveTime = 0
) => {
  const now = useNow()
  const isStarted = useMemo(() => startTime <= now, [startTime, now])
  const isEnded = useMemo(() => endTime <= now, [endTime, now])
  const isClaim = useMemo(() => receiveTime <= now, [receiveTime, now])
  const next = useMemo(() => {
    const stages = [startTime, endTime, receiveTime]
    const next = stages.find((stage) => stage > now)

    return next ? next : receiveTime
  }, [now, startTime, endTime, receiveTime])

  return {
    now,
    next,
    started: { ts: startTime, active: isStarted },
    ended: { ts: endTime, active: isEnded },
    claim: { ts: receiveTime, active: isClaim }
  }
}
