import { FC, PropsWithChildren, useEffect } from "react";
import { Web3ReactProvider } from "@web3-react/core";
import metamaskConnector from "@/connectors/metamask";
import networkConnector, { network } from "@/connectors/network";

const connectors = [networkConnector, metamaskConnector];
const Provider: FC<PropsWithChildren> = ({ children }) => {
  useEffect(() => {
    console.log("Activating network connector");
    network.activate().catch(() => {
      console.debug("Failed to connect to network");
    });
  }, []);

  return (
    <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>
  );
};

export default Provider;
