import { FC, PropsWithChildren } from 'react';
import loadingIcon from '@/assets/loading.svg';

interface ButtonProps {
  type?: 'primary' | 'danger';
  disabled?: boolean;
  loading?: boolean;
  onClick?: () => void;
}

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  type = 'primary',
  disabled,
  loading,
  children,
  onClick,
}) => {
  return (
    <button
      className={`w-full rounded-full py-2 px-4 flex items-center justify-center outline-none whitespace-nowrap ${
        type === 'danger' ? 'bg-red' : 'bg-blue'
      }${disabled ? ' opacity-[.60]' : ''}${
        disabled || loading ? ' pointer-events-none' : ''
      }`}
      onClick={onClick}
      type="button"
      disabled={disabled || loading}
    >
      <span className="inline-flex justify-center text-white text-[14px] leading-[18px]">
        {children}
      </span>
      {loading && (
        <img
          className="inline-block w-4 h-4 ml-2"
          src={loadingIcon}
          alt="loading"
        />
      )}
    </button>
  );
};

export default Button;
