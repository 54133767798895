import { FC, PropsWithChildren } from 'react';
import { hooks } from '@/connectors/metamask';
import { CHAIN_ID } from '@/constants/chain';
import Button from '@/components/common/Button';
import WalletButton from '@/components/common/WalletButton';

const { useAccount, useChainId } = hooks;

const CheckWallet: FC<PropsWithChildren> = ({ children }) => {
  const account = useAccount();
  const chainId = useChainId();

  if (!account) return <WalletButton />;
  if (chainId !== CHAIN_ID) return <Button disabled>Switch Network</Button>;
  return <>{children}</>;
};

export default CheckWallet;
