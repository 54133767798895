import { FC } from "react";
import Dialog from "@/components/common/Dialog";
import metaMaskIcon from "@/assets/metamask.svg";
import Button from "@/components/common/Button";

interface ConnectDialogProps {
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

const ConnectDialog: FC<ConnectDialogProps> = ({
  open,
  onClose,
  onConfirm,
}) => {

  return (
    <Dialog isOpen={open} onClose={onClose} title="Connect with Wallet">
      <div className="flex flex-col gap-10 mt-10">
        <section className="flex flex-col items-center gap-5">
          <img className="w-16" src={metaMaskIcon} alt="metamask" />
          <span className="text-white text-[16px] leading-5 font-bold">
            Connect to MetaMask
          </span>
        </section>
        <Button onClick={onConfirm}>
          <div className="py-1">
            {(window as any).ethereum ? "Connect" : "Install metamask"}
          </div>
        </Button>
      </div>
    </Dialog>
  );
};

export default ConnectDialog;
