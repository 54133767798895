import { FC, useMemo } from 'react';
import { useNow } from '@/hooks/time';

interface CountDownProps {
  ts: number;
}

function formatValue(value: number) {
  const str = value + '';
  return str.length === 1 ? '0' + str : str;
}

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

function getCountDown(seconds: number) {
  const result = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };
  let current = seconds;
  if (current >= DAY) {
    result.days = Math.floor(current / DAY);
    current = current % DAY;
  }
  if (current >= HOUR) {
    result.hours = Math.floor(current / HOUR);
    current = current % HOUR;
  }
  if (current >= MINUTE) {
    result.minutes = Math.floor(current / MINUTE);
    current = current % MINUTE;
  }
  result.seconds = current;

  return result;
}

const TimeCountDown: FC<CountDownProps> = ({ ts }) => {
  const now = useNow();
  const countDown = useMemo(
    () => getCountDown(ts - now > 0 ? ts - now : 0),
    [now, ts],
  );

  return (
    <div>
      <div className="flex gap-4 mt-4">
        <div className="w-1/3 py-3 flex flex-col items-center bg-black_02 rounded-[16px]">
          <div className="text-white text-[36px] leading-[47px] font-bold">
            {formatValue(countDown.days * 24 + countDown.hours)}
          </div>
          <div className="text-gray_1 text-[12px] leading-4">Hours</div>
        </div>
        <div className="w-1/3 py-3 flex flex-col items-center bg-black_02 rounded-[16px]">
          <div className="text-white text-[36px] leading-[47px] font-bold">
            {formatValue(countDown.minutes)}
          </div>
          <div className="text-gray_1 text-[12px] leading-4">mins</div>
        </div>
        <div className="w-1/3 py-3 flex flex-col items-center bg-black_02 rounded-[16px]">
          <div className="text-white text-[36px] leading-[47px] font-bold">
            {formatValue(countDown.seconds)}
          </div>
          <div className="text-gray_1 text-[12px] leading-4">Seconds</div>
        </div>
      </div>
    </div>
  );
};

export default TimeCountDown;
