import { useMemo } from 'react'
import { Contract } from '@ethersproject/contracts'
import { hooks } from '@/connectors/network'
import { MULTICALL3 } from '@/constants/contract'
import MULTICALL3_ABI from '@/abis/Multicall3.json'
import { Multicall3 } from '@/typechain'

const { useProvider } = hooks

export const useMulticall = () => {
  const provider = useProvider()

  return useMemo(
    () =>
      new Contract(MULTICALL3, MULTICALL3_ABI, provider) as unknown as Multicall3,
    [provider]
  )
}
