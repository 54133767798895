import { useEffect, useState } from 'react'
import { BigNumber } from '@ethersproject/bignumber'
import { Contract } from '@ethersproject/contracts'
import { hooks } from '@/connectors/metamask'
import { useBlockNumber } from './blockNumber'
import ERC20_ABI from '@/abis/ERC20.json'
import { ERC20 } from '@/typechain'

const { useAccount, useProvider } = hooks

const ZERO = BigNumber.from(0)

export const useERC20Balance = (token: string) => {
  const account = useAccount()
  const provider = useProvider()
  const [balance, setBalance] = useState<BigNumber | null>(null)
  const blockNumber = useBlockNumber()

  useEffect(() => {
    if (account && provider) {
      const erc20 = new Contract(token, ERC20_ABI, provider) as unknown as ERC20

      erc20
        .balanceOf(account)
        .then((res) => {
          setBalance(res)
        })
        .catch(() => setBalance(ZERO))
    } else {
      setBalance(null)
    }
  }, [account, provider, token, blockNumber, setBalance])

  return balance
}


export const useETHBalance = () => {
  const account = useAccount()
  const provider = useProvider()
  const [balance, setBalance] = useState<BigNumber | null>(null)
  const blockNumber = useBlockNumber()

  useEffect(() => {
    if (account && provider) {
      provider
        .getBalance(account)
        .then((res) => {
          setBalance(res)
        })
        .catch(() => setBalance(ZERO))
    } else {
      setBalance(null)
    }
  }, [account, provider, blockNumber, setBalance])

  return balance
}
