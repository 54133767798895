import { createContext, useState, useEffect, FC, PropsWithChildren } from 'react'
import { hooks } from '@/connectors/network'

const { useProvider } = hooks

export const BlockNumberContext = createContext(0)

const BlockNumber: FC<PropsWithChildren> = ({ children }) => {
  const provider = useProvider()
  const [blockNumber, setBlockNumber] = useState(0)

  useEffect(
    () => {
      let timer: NodeJS.Timeout
      const listener = (blockNumber: number) => {
        if (timer) clearTimeout(timer)
        timer = setTimeout(() => setBlockNumber(blockNumber), 800)
      }
      provider?.on('block', listener)

      return () => {
        provider?.off('block', listener)
      }
    }, 
    [provider, setBlockNumber]
  )

  return (
    <BlockNumberContext.Provider value={blockNumber}>
      {children}
    </BlockNumberContext.Provider>
  )
}

export default BlockNumber;
