import { useMobile } from '@/hooks/mobile';
import { FC } from 'react';
import { ReactComponent as LogoIcon } from '@/assets/logo.svg';
import { ReactComponent as LinkIcon1 } from '@/assets/linkIcon1.svg';
import { ReactComponent as LinkIcon2 } from '@/assets/linkIcon2.svg';
import { ReactComponent as LinkIcon3 } from '@/assets/linkIcon3.svg';
import { ReactComponent as LinkIcon4 } from '@/assets/linkIcon4.svg';
import { ReactComponent as LinkIcon5 } from '@/assets/linkIcon5.svg';
import WalletButton from '@/components/common/WalletButton';

const Header: FC = () => {
  const isMobile = useMobile();

  return (
    <div className="h-[50px] shrink-0 px-4 sm:px-8 flex items-center justify-between bg-black/50">
      <div className="flex items-center">
        <LogoIcon className="w-6 h-6" />
        <div className="hidden sm:block ml-2 text-white text-[20px] leading-[32px]">
          ChatFiAI
        </div>
      </div>
      <div className="flex items-center gap-8">
        <div className="items-center hidden gap-8 sm:flex">
          <a
            href="https://twitter.com/ChatfiAI"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon1 className="w-5 h-5" />
          </a>
          <a
            href="https://discord.gg/CaXKsd258c"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon2 className="w-5 h-5" />
          </a>
          <a href="https://t.me/ChatFi_Global" target="_blank" rel="noreferrer">
            <LinkIcon3 className="w-5 h-5" />
          </a>
          <a
            href="https://medium.com/@ChatfiAI"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon4 className="w-5 h-5" />
          </a>
          <a
            href="https://chatfiai.gitbook.io/whitepaper/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon5 className="w-5 h-5" />
          </a>
        </div>
        <div className="flex items-center gap-3 sm:gap-4">
          <a
            className="text-white text-[14px] px-4 py-2 border-blue border-[2px] rounded-[16px] box-border relative outline-none h-[34px] flex items-center whitespace-nowrap"
            href="https://medium.com/@ChatFiAI/chatfi-launch-details-and-tokenomics-1140fd16cd3"
            target="_blank"
            rel="noreferrer"
          >
            {isMobile ? 'Launch Details' : 'Token Launch Details'}
          </a>
          <WalletButton />
        </div>
      </div>
    </div>
  );
};

export default Header;
