import { FC } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as LogoIcon } from '@/assets/logo.svg';
import { useList } from '@/hooks/presale';
import { useNow } from '@/hooks/time';
import * as currency from '@/helpers/currency';
import Loading from '@/components/common/Loading';
import Error from '@/components/common/Error';
import PRESALES from '@/constants/presales';

dayjs.extend(utc);

const LIST = Object.keys(PRESALES);

const Launchpad: FC = () => {
  const { list, error } = useList(LIST);
  const now = useNow();
  const navigate = useNavigate();

  if (error) return <Error />;
  if (!list) return <Loading />;
  return (
    <div className="py-8 px-[34.5px] sm:py-12 sm:px-16 h-full overflow-auto">
      <div className="text-white text-[36px] leading-[41px] sm:text-[48px] sm:leading-[55px] font-[Android101] text-center sm:text-left">
        CHATFI.AI Launchpad
      </div>
      <div className="mt-2 text-white text-[14px] leading-[20px] sm:text-[20px] sm:leading-[36px]">
        Building AI-chat Web3 app for millions, shaping the harmonious future of
        Blockchain and AI.
      </div>
      <div className="flex flex-wrap mt-6 sm:mt-9 sm:gap-x-9 gap-y-6">
        {list.map((item) => (
          <div
            className="w-full sm:w-[258px] p-6 bg-black/50 rounded-[16px] cursor-pointer"
            key={item.address}
            onClick={() => navigate(`/accelerate/${item.address}`)}
          >
            <div className="flex items-center pb-2 border-b border-solid border-blue">
              <LogoIcon className="w-6 h-6" />
              <div className="ml-2 text-white text-[16px] leading-[21px]">
                {PRESALES[item.address] ? 'Private' : 'Public'} sale
              </div>
            </div>
            <div className="flex items-start justify-between mt-6">
              <div className="flex flex-col items-start gap-3">
                <div>
                  <div className="text-orange text-[12px] leading-4">
                    Hard cap
                  </div>
                  <div className="text-white text-[16px] leading-[21px]">
                    {currency.format(item.ethersToRaise, 18)} ETH
                  </div>
                </div>
              </div>
              {item.startTime > now ? (
                <div className="flex px-2 py-1 border border-solid rounded-full border-blue_01">
                  <div className="text-blue_01 text-[12px] leading-4 whitespace-nowrap">
                    Coming soon
                  </div>
                </div>
              ) : item.endTime > now ? (
                <div className="flex px-2 py-1 border border-solid rounded-full border-green">
                  <div className="text-green text-[12px] leading-4 whitespace-nowrap">
                    Ongoing
                  </div>
                </div>
              ) : (
                <div className="flex px-2 py-1 border border-solid rounded-full border-red">
                  <div className="text-red text-[12px] leading-4 whitespace-nowrap">
                    Closed
                  </div>
                </div>
              )}
            </div>
            <div className="mt-3">
              <div>
                <div className="text-orange text-[12px] leading-4">
                  Total raised
                </div>
                <div className="text-white text-[16px] leading-[21px]">
                  {currency.format(item.totalCommitments, 18)} ETH
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="text-orange text-[12px] leading-4">Time</div>
              <div className="text-white text-[16px] leading-[21px]">
                {dayjs
                  .unix(item.startTime)
                  .utc()
                  .format('ha [UTC], D[th] MMMM, YYYY')}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Launchpad;
