import { FC } from 'react';

interface InputNumberProps {
  disabled: boolean;
  value: string;
  onChange: (value: string) => void;
  textAlign?: 'left' | 'right';
  onFocus?: () => void;
  onBlur?: () => void;
}

const InputNumber: FC<InputNumberProps> = ({
  value,
  onChange,
  disabled,
  textAlign = 'right',
  onFocus = () => {},
  onBlur = () => {},
}) => {
  return (
    <input
      className={`w-full h-[14px] outline-none text-[14px] leading-1 text-white font-medium bg-transparent placeholder:text-gray_02 text-${textAlign}`}
      disabled={disabled}
      type="number"
      placeholder="0.00"
      value={value}
      onChange={({ target: { value } }) => {
        if (Number(value) >= 0) {
          onChange(value);
        }
      }}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default InputNumber;
