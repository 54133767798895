import { initializeConnector } from "@web3-react/core";
import { Network } from "@web3-react/network";
import { CHAIN_ID, NETWORK_RPC_URL } from "@/constants/chain";

const connector = initializeConnector<Network>(
  (actions) => new Network({ actions, urlMap: { [CHAIN_ID]: NETWORK_RPC_URL } })
);

export const [network, hooks] = connector;
export default connector;
