import { useState, useEffect } from 'react'

export const useNow = () => {
  const [now, setNow] = useState(Math.floor(Date.now() / 1000))

  useEffect(() => {
    let timer: NodeJS.Timeout
    const set = () => {
      setNow(Math.floor(Date.now() / 1000))
      timer = setTimeout(set, 800)
    }

    set()

    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [])

  return now
}