import { FC, PropsWithChildren } from 'react'
import { useIsWhitelist, useIsPublicSale } from '@/hooks/whitelist'
import Button from '@/components/common/Button'

interface Props {
  presale: string
  started: boolean
}

const Whitelist: FC<PropsWithChildren<Props>> = ({ children, presale, started }) => {
  const isWhitelist = useIsWhitelist(presale)
  const isPublicSale = useIsPublicSale(presale)

  if (isPublicSale) {
    if (started) {
      return <>{children}</>
    } else {
      return <Button disabled>Wait for the opening</Button>
    }
  } else {
    if (!isWhitelist) {
      return <Button disabled>You are not whitelisted</Button>
    } else if (started) {
      return <>{children}</>
    } else {
      return <Button disabled>You are whitelisted</Button>
    }
  }
}

export default Whitelist
