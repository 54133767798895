import { FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import LaunchpadPage from '@/pages/launchpad';
import AcceleratePage from '@/pages/accelerate';
import { useMobile } from '@/hooks/mobile';
import backgroundImage from '@/assets/background.svg';

const Layout: FC = () => {
  const isMobile = useMobile();

  return (
    <div
      className="h-[100vh] overflow-auto sm:flex sm:flex-col bg-black_01 bg-top bg-no-repeat bg-blend-lighten"
      style={{
        backgroundImage: !isMobile ? `url(${backgroundImage})` : 'none',
      }}
    >
      <Header />
      <Routes>
        <Route path="/" element={<Main />}>
          <Route index element={<LaunchpadPage />} />
          <Route path="launchpad" element={<LaunchpadPage />} />
          <Route path="accelerate/:address" element={<AcceleratePage />} />
        </Route>
      </Routes>
      <Footer />
    </div>
  );
};

export default Layout;
