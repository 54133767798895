import { FC, useEffect, useState } from 'react';
import Button from '@/components/common/Button';
import { metamask, hooks } from '@/connectors/metamask';
import ConnectDialog from '@/components/common/ConnectDialog';
import { NETWORK_CONFIG } from '@/constants/chain';
import type { MetaMaskInpageProvider } from '@metamask/providers';

const { useChainId, useAccount } = hooks;

const WalletButton: FC = () => {
  const currentChainId = useChainId();
  const account = useAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addNetwork, setAddNetwork] = useState(false);

  useEffect(() => {
    if ((window as any).ethereum) {
      metamask.activate(NETWORK_CONFIG).catch((error) => {
        console.error('Failed to connect', error);
      });
    }
  }, [addNetwork]);

  const onConfirm = () => {
    if ((window as any).ethereum) {
      metamask.activate(NETWORK_CONFIG);
    } else {
      window.open(
        'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
      );
    }
    setIsModalOpen(false);
  };

  const switchAndAddNetwork = async () => {
    const provider = (window as any).ethereum as MetaMaskInpageProvider;
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${NETWORK_CONFIG.chainId.toString(16)}` }],
      });
    } catch ({ data: switchError }) {
      const _error = switchError as { originalError: { code: number } };
      if (_error?.originalError?.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                ...NETWORK_CONFIG,
                chainId: `0x${NETWORK_CONFIG.chainId.toString(16)}`,
              },
            ],
          });
          setAddNetwork(true);
        } catch (addError) {
          console.error('Failed to add network', addError);
        }
      }
    }
  };

  return (
    <>
      {!account ? (
        <div>
          <Button onClick={() => setIsModalOpen(true)}>Connect Wallet</Button>
        </div>
      ) : (
        <>
          {currentChainId === NETWORK_CONFIG.chainId ? (
            <span className="px-4 py-2 text-white bg-blue rounded-[16px] cursor-pointer box-border h-[34px] flex items-center">{`${
              account.slice(0, 3) + '...' + account.slice(-3)
            }`}</span>
          ) : (
            <Button type="danger" onClick={() => switchAndAddNetwork()}>
              Switch to zkSync
            </Button>
          )}
        </>
      )}
      <ConnectDialog
        open={isModalOpen}
        onConfirm={onConfirm}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default WalletButton;
