import { FC } from 'react';
import { ReactComponent as LogoIcon } from '@/assets/logo.svg';
import { ReactComponent as LinkIcon1 } from '@/assets/linkIcon1.svg';
import { ReactComponent as LinkIcon2 } from '@/assets/linkIcon2.svg';
import { ReactComponent as LinkIcon3 } from '@/assets/linkIcon3.svg';
import { ReactComponent as LinkIcon4 } from '@/assets/linkIcon4.svg';
import { ReactComponent as LinkIcon5 } from '@/assets/linkIcon5.svg';

const Footer: FC = () => {
  return (
    <div className="h-[209px] sm:h-[50px] shrink-0 flex flex-col gap-2 sm:flex-row px-8 py-[22.5px] sm:py-2 justify-start sm:justify-between bg-black/50">
      <div className="flex flex-col items-start gap-2 sm:flex-row sm:items-center">
        <div className="flex items-center sm:mr-[37px]">
          <LogoIcon className="w-6 h-6" />
          <div className="ml-2 text-white text-[20px] leading-[32px] font-bold">
            ChatFiAI
          </div>
        </div>
        <a 
          className="text-white text-[16px] leading-6 underline"
          href="mailto:contact@chatfi.ai"
          rel="noreferrer"
        >
          contact@chatfi.ai
        </a>
      </div>
      <div className="flex flex-col items-start gap-4 sm:flex-row sm:items-center">
        <div className="flex flex-col gap-2 sm:flex-row">
          <a 
            className="sm:mr-[37px] text-white text-[16px] leading-6"
            href="https://drive.google.com/drive/folders/1cZ4mNas7F2Db4KNQHxwPm8QVm7-qQzsQ"
            target="_blank"
            rel="noreferrer"
          >
            Media Kit
          </a>
          <a 
            className="sm:mr-[29px] text-white text-[16px] leading-6"
            href="https://chatfiai.gitbook.io/whitepaper/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Policy
          </a>
        </div>
        <div className="flex justify-between w-full sm:w-auto sm:gap-8">
          <a
            href="https://twitter.com/ChatfiAI"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon1 className="w-5 h-5" />
          </a>
          <a
            href="https://discord.gg/CaXKsd258c"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon2 className="w-5 h-5" />
          </a>
          <a href="https://t.me/ChatFi_Global" target="_blank" rel="noreferrer">
            <LinkIcon3 className="w-5 h-5" />
          </a>
          <a
            href="https://medium.com/@ChatfiAI"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon4 className="w-5 h-5" />
          </a>
          <a
            href="https://chatfiai.gitbook.io/whitepaper/"
            target="_blank"
            rel="noreferrer"
          >
            <LinkIcon5 className="w-5 h-5" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
